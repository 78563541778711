var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row topmainrow" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "div",
              { staticClass: "topmainlayer card", style: _vm.topmainHeight },
              [
                _c("div", { staticClass: "row topmainbtns" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "maincard card",
                          on: {
                            click: function ($event) {
                              return _vm.goPage("/sop/swp/safeWorkPermit")
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card-body border-color-green" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs fw-bold text-white mb-1",
                                      },
                                      [_vm._v("안전작업허가")]
                                    ),
                                    _c("div", { staticClass: "h5" }, [
                                      _vm._v(
                                        _vm._s(_vm.data.workPermitCnt) + " 건"
                                      ),
                                    ]),
                                  ]),
                                  _vm._m(0),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "maincard card",
                          on: {
                            click: function ($event) {
                              return _vm.goPage(
                                "/sop/edu/result/educationResult"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card-body border-color-blue" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs fw-bold text-white mb-1",
                                      },
                                      [_vm._v("교육")]
                                    ),
                                    _c("div", { staticClass: "h5" }, [
                                      _vm._v(_vm._s(_vm.data.eduCnt) + " 건"),
                                    ]),
                                  ]),
                                  _vm._m(1),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "maincard card",
                          on: {
                            click: function ($event) {
                              return _vm.goPage("/sai/patrol/patrol")
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card-body border-color-red" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs fw-bold text-white mb-1",
                                      },
                                      [_vm._v("순회점검")]
                                    ),
                                    _c("div", { staticClass: "h5" }, [
                                      _vm._v(
                                        _vm._s(_vm.data.patrolCnt) + " 건"
                                      ),
                                    ]),
                                  ]),
                                  _vm._m(2),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 topcarddiv",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "maincard card",
                          on: {
                            click: function ($event) {
                              return _vm.goPage("/sop/iim/near/nearAccident")
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card-body border-color-yullow" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs fw-bold text-white mb-1",
                                      },
                                      [_vm._v("아차사고")]
                                    ),
                                    _c("div", { staticClass: "h5" }, [
                                      _vm._v(
                                        _vm._s(_vm.data.nearAccCnt) + " 건"
                                      ),
                                    ]),
                                  ]),
                                  _vm._m(3),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "row mainbottomlayer" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c("div", { staticClass: "card cardcontents2" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 개선관리 "),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sop/ibm/impr")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body no-padding-card" },
                [
                  _c("q-table", {
                    attrs: {
                      grid: "",
                      title: "",
                      data: _vm.data.imprList,
                      "hide-header": "",
                      "hide-bottom": "",
                      "rows-per-page-options": [0],
                      "virtual-scroll": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (props) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",
                              },
                              [
                                _c(
                                  "q-card",
                                  {
                                    staticClass: "mobileTableCardLayer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getDetailPage(
                                          "IMPROVE",
                                          props.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "q-card-section",
                                      { staticClass: "grid-card-etc" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "text-grid-etc",
                                              _vm.getColorStatus(
                                                props.row.ibmStepCd
                                              ),
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.row.ibmStepName) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "text-grid-etc",
                                              _vm.getColorStatus2(
                                                props.row.ibmTaskTypeCd
                                              ),
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.row.ibmTaskTypeName
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("q-card-section", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-grid-title q-mt-sm q-mb-xs",
                                        },
                                        [_vm._v(_vm._s(props.row.ibmTitle))]
                                      ),
                                    ]),
                                    _c(
                                      "q-card-section",
                                      { staticClass: "grid-card-etc" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "txt-box-grid text-gray-box",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.row.requestDeptName
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              [
                                                props.row.completeDateOver > 0
                                                  ? _c("q-badge", {
                                                      attrs: {
                                                        color: "negative",
                                                        label: "지연",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "text-grid-etc",
                                            style:
                                              props.row.approvalStatusCd ===
                                              "ASC9999999"
                                                ? "text-decoration: line-through"
                                                : "",
                                          },
                                          [
                                            _vm._v(
                                              " ~ " +
                                                _vm._s(
                                                  props.row
                                                    .actionCompleteRequestDate
                                                ) +
                                                " "
                                            ),
                                            props.row.completeDateOver > 0 &&
                                            props.row.ibmStepCd !==
                                              "IS00000001" &&
                                            props.row.approvalStatusCd !==
                                              "ASC9999999"
                                              ? _c("q-badge", {
                                                  staticClass: "blinking",
                                                  attrs: {
                                                    color: "negative",
                                                    label: "지연",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        props.row.approvalStatusCd ===
                                        "ASC9999999"
                                          ? _c("div", [
                                              _vm._v(
                                                "👉 ~ " +
                                                  _vm._s(props.row.targetDate)
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.data.imprList.length === 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",
                        },
                        [
                          _c(
                            "q-card",
                            { staticClass: "mobileTableCardLayer" },
                            [
                              _c(
                                "q-card-section",
                                {
                                  staticClass: "text-center",
                                  attrs: { horizontal: "" },
                                },
                                [
                                  _c(
                                    "q-card-section",
                                    { staticClass: "q-pt-xs" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-grid-title text-grid-titlemain q-mt-sm q-mb-xs",
                                        },
                                        [_vm._v("등록된 개선관리가 없습니다.")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c("div", { staticClass: "card cardcontents2" }, [
              _c("div", { staticClass: "card-header" }, [_vm._v(" 공지사항 ")]),
              _c(
                "div",
                { staticClass: "card-body no-padding-card" },
                [
                  _c("q-table", {
                    attrs: {
                      grid: "",
                      title: "",
                      data: _vm.data.noticeList,
                      "hide-header": "",
                      "hide-bottom": "",
                      "rows-per-page-options": [0],
                      "virtual-scroll": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (props) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",
                              },
                              [
                                _c(
                                  "q-card",
                                  {
                                    staticClass: "mobileTableCardLayer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getDetailPage(
                                          "NOTICE",
                                          props.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "q-card-section",
                                      { staticClass: "grid-card-etc" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "text-grid-etc",
                                              _vm.getColorStatus3(
                                                props.row.noticeClassCd
                                              ),
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.row.noticeClassName
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("q-card-section", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-grid-title q-mt-sm q-mb-xs",
                                        },
                                        [_vm._v(_vm._s(props.row.noticeTitle))]
                                      ),
                                    ]),
                                    _c(
                                      "q-card-section",
                                      { staticClass: "grid-card-etc" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "text-grid-etc" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.row.regDtStr) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.data.noticeList.length === 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",
                        },
                        [
                          _c(
                            "q-card",
                            { staticClass: "mobileTableCardLayer" },
                            [
                              _c(
                                "q-card-section",
                                {
                                  staticClass: "text-center",
                                  attrs: { horizontal: "" },
                                },
                                [
                                  _c(
                                    "q-card-section",
                                    { staticClass: "q-pt-xs" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-grid-title text-grid-titlemain q-mt-sm q-mb-xs",
                                        },
                                        [_vm._v("등록된 공지사항이 없습니다.")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ms-2" }, [
      _c("span", { staticClass: "material-icons" }, [_vm._v("handyman")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ms-2" }, [
      _c("span", { staticClass: "material-icons" }, [_vm._v("school")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ms-2" }, [
      _c("span", { staticClass: "material-icons" }, [_vm._v("engineering")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ms-2" }, [
      _c("span", { staticClass: "material-icons" }, [_vm._v("warning")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }